import request from '@/utils/request'


export function getOrder(data) {
  return request({
    url: 'oms/oms/order/ad3df2283c1dc4426f4c6ca83e5529a4',
    method: 'post',
    data: data
  })
}

export function savePcc(data) {
  return request({
    url: 'oms/oms/order/2e38a01f24cc5e33ebfd55b5b5e9b58d',
    method: 'post',
    data: data
  })
}

export function checkPcc(data) {
  return request({
    url: 'oms/oms/order/5ce1765669ce2a9f87272f7b00e9aab0',
    method: 'post',
    data: data
  })
}

