<template>
  <div class="home">
    <div class="header">개인통관고유부호 사비스
      <img src="images/menu.svg" alt="" class="menu" @click="close()">
    </div>
    <img src="images/main1.png" class="swipe-img" width="100%"/>
    <div class="container">
      <div class="row mb-1 pb-1">
        <div class="row mb-1 pb-1" style="border:1px solid #3089fc;padding:10px 8px;margin: 10px 0;">
          <div class="col-md-10 text-center">
            <h2 class="mb-1">통관 정보 확인</h2>
          </div>
          <div class="wrapper">
            <div class="row col-12">
              <p class="f10" style="color:#f76026;text-align: left; font-size: 14px; font-weight: bold; word-break: break-all">
                도움말： 통관번호가 있는 상품은 배송기간이 더 짧으며, 통관번호가 없는 경우 아래 링크를 클릭하시면 신청하실 수 있습니다. 다른 통관 번호에 관한 문의는 아래 카카오 ID로 연락주세요. kakao: 주식회사 다익-GBS<br/>
                <a href="https://unipass.customs.go.kr/csp/persIndexRectOnslCrtf.do?qryIssTp=2"
                   style="color: #0067ee; cursor: pointer; "
                   target="_blank"
                >https://unipass.customs.go.kr/csp/persIndexRectOnslCrtf.do?qryIssTp=2</a>
              </p>
            </div>
            <div class="row tbl key-value">
              <div class="col-md-3 col-4 key">고객명</div>
              <div class="col-md-9 col-8 value" :class="{'error': !form.consigneeStar && saving }"><input type="text" class="my-input input-disabled" v-model="form.consigneeStar" placeholder="성명" readonly></div>
            </div>
            <div class="row tbl key-value">
              <div class="col-md-3 col-4 key">통관부호: P</div>
              <div class="col-md-9 col-8 value" :class="{'error': !isValid}"><input type="text" class="my-input" v-model="form.pccInput" placeholder="(12자리 통관번호를 입력하세요)"></div>
            </div>
            <p class="f10">
              <span>※ 21.07.01 통관부호 검증강화 발급시 등록된 연락처 필수.<span>
					</span></span>
            </p>
            <div class="text-center">
              <van-button type="info" @click="savePcc" style="border-radius: 10px;background: #1989fa;font-weight: bolder;">통관부호 저장</van-button>
            </div>
          </div>
        </div>
        <div class="col-md-11 text-center">
          <h2 class="mb-2">통관부호 등록</h2>
        </div>
        <div class="col-md-10 col-11 ftco-animate fadeInUp ftco-animated" style="border:1px solid #3089fc;padding:10px 8px;margin: 10px 0;">
          <p class="f10">
            관세청의 <strong style="color:#f76026;">"특송물품 수입통관 사무처리에 관한 고시"</strong> 시행으로 <strong style="color:#f76026;">`2019년 09월 01일`</strong>
            일부터 해외상품 구입시 수취인의 식별부호(개인통관고유부호)를 필수로 입력해야 합니다.
            <br>개인통관고유부호는 아래 "개인통관고유부호 신규발급 바로가기"에서 발급받으실 수 있습니다. 외국인의 경우 주등록번호 항목에 외국인등록번호를 입력하여 개인통관고유부호를 발급받으시면 되겠습니다. 외국인등록번호가 없는 외국인의 경우 개인통관고유부호 발급없이 여권번호로도 통관이 가능하겠습니다. 개인통관고유부호 혹은 여권번호 미입력시 통관이 불가하여 배송이 지연될 수 있는 점 많은 양해 부탁드립니다.
          </p>
          <p>
            <a href="https://unipass.customs.go.kr/csp/persIndexRectOnslCrtf.do?qryIssTp=2" target="new" class="btn btn-primary btn-sm"><span>개인통관부호 신규발급 바로가기</span></a>
          </p>
          <p></p>
          <p class="f10">
            # 반드시 구매자님의 성명과 제출자료의 성명이 일치하셔야 합니다.
          </p>
          <p class="f10" style="color:#f76026">
            # <strong>개인통관고유부호 오류건도 미 기재건으로 간주</strong>
          </p>
          <p class="f10">
            [수하인 식별부호 기재 예시]<br>
            ▶ 개인인 경우<br>
            &nbsp;&nbsp;&nbsp;» 개인통관고유부호: 영문P로 시작하는 숫자 12자리 입니다.<br>
            &nbsp;&nbsp;&nbsp;» 예시: P123456789011<br>
            ▶ 사업자인 경우<br>
            &nbsp;&nbsp;&nbsp;» 신고 제출 서류와 동일한 사업자번호정보<br>
          </p>
        </div>
      </div>
    </div>
    <div class="footer">
      <van-row class="van-row-1">
        <van-col span="18" class="row-text">인천시 서구 원당동 대림아파트 108동 </van-col>
      </van-row>
      <van-row class="van-row-1">
        <van-col span="18" class="row-text">월~금 09:00-18:00/토 일 공휴일 휴무</van-col>
      </van-row>
    </div>
    <van-popup v-model="editModal" closeable
               close-icon="close"
               position="bottom"
               :style="{ height: '50%' }" >
      <div class="wrapper">
        <p>통관번호 문의</p>
        <div class="row tbl key-value">
          <div class="col-md-3 col-4 key"><span class="star">*</span>고객명</div>
          <div class="col-md-9 col-8 value" :class="{'error': !form.consignee && saving }"><input type="text" class="my-input" v-model="form.consignee" placeholder="성명"></div>
        </div>
        <div class="row tbl key-value">
          <div class="col-md-3 col-4 key"><span class="star">*</span>통관부호: P</div>
          <div class="col-md-9 col-8 value" :class="{'error': !isValid}"><input type="text" class="my-input" v-model="form.pccInput" placeholder="(12자리 통관번호를 입력하세요)"></div>
        </div>
        <p class="f10">
              <span>※ 21.07.01 통관부호 검증강화 발급시 등록된 연락처 필수.<span>
					</span></span>
        </p>
        <div class="text-center">
          <van-button type="info" @click="savePcc" style="border-radius: 10px;background: #1989fa;font-weight: bolder;">통관부호 저장</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import {getOrder, savePcc} from '../../api/portal'
  import {Dialog, Toast} from 'vant';
  export default {
    name: 'Home',
    data () {
      return {
        timer: null,
        updateModal: false,
        saving: false,
        iccModal: false,
        editModal: false,
        deliveryNo: '',
        voyageNo: 'GBKR-WHAC',
        form: {
          deliveryNo: '',
          orderNo: '',
          phone: '',
          consignee: '',
          consigneeStar: '',
          pccInput: '',
          pcc: ''
        },
        order: {
          deliveryNo: '',
          orderNo: '',
          phone: '',
          goods: '',
          consignee: '',
          pcc: ''
        }
      }
    },
    computed: {
      isValid () {
        return this.validateString(this.form.pccInput)
      }
    },
    created() {
      this.hbr = this.$route.params.no
      if (this.hbr) {
        this.getOrder()
      }
    },
    methods: {
       validateString (str) {
        let regex = /^(p|P)\d{12}$|^\d{12}$/
        return regex.test(str)
      },
      async getOrder () {
        let params = {
          hbr: this.hbr
        }
        let resp = await getOrder(params)
        if (resp.success) {
          this.order = resp.data
          this.form.orderNo = this.order.orderNo
          this.form.deliveryNo = this.order.deliveryNo
          this.form.phone = this.order.phone
          this.form.pcc = null
          this.form.consignee = this.order.consignee
          this.form.consigneeStar = this.order.consignee.substring(0, 1) + '*' + this.order.consignee.substring(2)
        }
      },
      async savePcc () {
        this.saving = true
        if (!this.form.consignee) {
          return
        }
        if (!this.form.phone || this.form.phone.length < 11) {
          return
        }
        if (!this.form.pccInput) {
          Toast.fail('통관 부호는 비워 둘 수 없습니다')
          return
        }
        if (!this.isValid) {
          Toast.fail('통관자 형식이 잘못되었습니다.')
          return
        }
        this.form.pccInput = this.form.pccInput.toUpperCase()
        if (!this.form.pccInput.startsWith('P')) {
          this.form.pcc = 'P' + this.form.pccInput
        } else {
          this.form.pcc = this.form.pccInput
        }
        let resp = await savePcc(this.form)
        this.editModal = false
        if (resp.data === '통관 부호가 올바르고 저장되었습니다') {
          Toast.success('통관 부호가 올바르고 저장되었습니다')
        } else {
          Dialog.confirm({
            title: '통관자 오류',
            confirmButtonText: '수정하기',
            cancelButtonText: '닫다.',
            message: '통관자 오류입니다. 수신자와 통관자가 일치하는지 확인하십시오.',
          })
              .then(() => {
                this.editModal = true
              })
              .catch(() => {
                // on cancel
              });
        }
      },
      getDate () {
        let now = new Date().getTime() + 86400000
        let tomorrow = new Date(now);
        let y = tomorrow.getFullYear();
        let m = tomorrow.getMonth() < 10 ? ('0' + tomorrow.getMonth()) : tomorrow.getMonth();
        let d = tomorrow.getDate() < 10 ? ('0' + tomorrow.getDate()) : tomorrow.getDate();
        return y + '-' + m + '-' + d + ' 12:00:00'
      },
      close () {
        location.href = 'about:blank'
      }
    }
  }
</script>

<style lang="scss">
  .home {
    height: 100vh;
    overflow: hidden;
    .header {
      position: relative;
      letter-spacing: 3px;
      height: 30px;
      /* background: #010a1c; */
      color: #010a1c;
      line-height: 30px;
      font-size: 20px;
      font-weight: bolder;
      text-align: left;
      padding-left: 10px;
      .menu {
        position: absolute;
        right: 2px;
        top: 0;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
    .swipe-img {
      display: block;
      height: 170px;
    }
    .container {
      position: relative;
      padding: 0 10px;
      height: calc(100vh - 270px);
      overflow-y: auto;
      h2 {
        color: #1989fa;
        font-size: 28px;
      }
    }
    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: block;
      font-size: 12px;
      background: rgba(59, 55, 55, 0.1);
      padding: 10px 0 20px;
      height: 40px;
      .van-row-1 {
        color: #0e0d0d;
        line-height: 20px;
        width: 100%;
        margin: 0 10px;
        .row-text {
          text-align: left;
          font-size: 12px;
          line-height: 20px;
          padding-left: 5px;
        }
      }
    }
  }
  .wrapper {
    border-top: 1px solid #1989fa;
    width: 100%;
    padding: 10px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    .col-4 {
      flex: 1;
    }
    .col-8 {
      flex: 2;
    }
    &.tbl {
      border-bottom: 1px solid #e5e5e5;
      padding: 5px 0;
      font-weight: bold;
      margin-bottom: 2px;
      div {
        border-bottom: 1px solid #e5e5e5;
        padding: 5px;
        font-weight: bold;
        margin: 4px 0;
      }
      .title {
        background: #eeeeee;
      }
      .cont {
        background: #FFFFFF;
        color: #333;
        font-size: 14px;
        text-align: left;
        word-break: break-all;
      }
    }
  }
  .text-center {
    width: 100%;
    text-align: center !important;
    .mb-1 {
      margin: 10px;
    }
    .mb-2 {
      margin: 0px;
    }
  }
  .btn.btn-primary {
    background: #1989fa;
    border: 1px solid #1989fa;
    color: #fff;
  }
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .van-popup {
    background: #ededed;
    .wrapper {
      width: 90% !important;
    }
  }
  .my-label {
    height: 24px;
    line-height: 24px;
  }
  .my-input {
    border: 1px solid #ededed;
    width: 100%;
    height: 24px;
    padding-left: 5px;
    &.input-disabled {
      border: none !important;
      cursor: default;
    }
  }
  .info-1 {
    margin: 0 !important;
    padding: 0 !important;
    border-bottom: none !important;
    .cont {
      border-bottom: none!important;
    }
  }
  .info-2 {
    margin: 0 !important;
    padding: 0 !important;
    border-bottom: none !important;
    .cont {
      border-bottom: none!important;
    }
  }
  .key-value {
    padding: 0 !important;
    display: inline-flex;
    line-height: 40px !important;
    height: 40px !important;
    border: none !important;
    margin: 10px 0 !important;
    background-color: #f5f5f5;
    clear: both;
    div {
      line-height: 40px !important;
      height: 40px !important;
      border: none !important;
      padding: 0 !important;
      margin: 0 !important;
    }
    .key {

    }
    .value {
      padding-right: 20px;
      &.error {
        .my-input {
          border: 1px solid red;
        }
      }
      .my-input {
        line-height: 38px;
        height: 38px;
        border-radius: 10px;
        border: 1px solid #3089fc;
      }
    }
    .star {
      color: red;
    }
  }
</style>
